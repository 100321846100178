import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

/* TODO
    -geometrie clipping wenn winkel hoch
*/

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Object
const geometry = new THREE.BoxGeometry(1, 1, 1)
const material = new THREE.MeshBasicMaterial({ color: 'grey' })
const mesh = new THREE.Mesh(geometry, material)
//scene.add(mesh)

const geometry2 = new THREE.BoxGeometry(300, 0.1, 200)
//const material2 = new THREE.MeshBasicMaterial({ color: 0xFFA500})
const material2 = new THREE.MeshPhongMaterial()
material2.color = new THREE.Color(0xFFA500)
const mesh2 = new THREE.Mesh(geometry2, material2)
mesh2.position.y = -0.51
scene.add(mesh2)
mesh2.receiveShadow = true

const geometry3 = new THREE.SphereGeometry( 200, 60, 40 );
geometry3.scale( - 1, 1, 1 );
const mesh3 = new THREE.Mesh(geometry3, material2)
scene.add(mesh3)

// Light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, 0.5)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)
pointLight.castShadow = true

// LOAD
const gltfLoader = new GLTFLoader()

//const material4 = new THREE.MeshNormalMaterial()
const material4 = new THREE.MeshPhongMaterial()
//material4.wireframe = true
//material4.side = THREE.DoubleSide
var loadID = 0

gltfLoader.load(
    '/models/monkey.gltf',
    (gltf) =>
    {
        console.log('success')
        gltf.scene.children[2].material = material4
        loadID = gltf.scene.children[2].id;
        scene.add(gltf.scene.children[2])
        scene.getObjectById(loadID).castShadow = true 
        scene.getObjectById(loadID).position.y = 1 
        
    }
)



// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const zoomFaktor = 3;

// camera
const aspectRatio = sizes.width / sizes.height
//const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 1, 1000)
const camera = new THREE.OrthographicCamera(-zoomFaktor * aspectRatio,zoomFaktor * aspectRatio,zoomFaktor,-zoomFaktor, 0.1, 1000)
camera.position.z = 5
scene.add(camera)

window.addEventListener('resize', () => 
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    //camera.aspect = sizes.width / sizes.height
    aspectRatio = sizes.width / sizes.height
    camera.left = -zoomFaktor * aspectRatio
    camera.right= zoomFaktor * aspectRatio
    camera.top  = zoomFaktor
    camera.bottom = -zoomFaktor
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () => 
{
    if(!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    } 
})

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxPolarAngle = Math.PI/2 -0.1;
//controls.enableRotate = false
controls.maxZoom = 1;   //wie nah
controls.minZoom = 0.2; // wie weit, kleiner = weiter

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true



/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{   
    const elapsedTime = clock.getElapsedTime()
    if(loadID!=0){
    scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
        //console.log("JOOOOO")
        //console.log(scene.getObjectByProperty("uuid", "DD1C1258-1956-4217-81FB-088E99C52954"))
    }
    //scene.children[5].rotation.y = elapsedTime * Math.PI *2 *0.01
    //mesh.rotation.y = elapsedTime * Math.PI *2 *0.01
    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()